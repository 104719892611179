.intro{
    display: flex;
    height: 100vh;
    /* margin-bottom: 10vh; */
}

.intro-left{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-right{
    flex: 1;
    position: relative;
}

.intro-left-wrapper{
    padding: 50px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.intro-saludo{
    font-size: 30px;
    font-weight: 300;
}

.intro-nombre{
    font-size: 60px;
}

.intro-title{
    height: 50px;
    overflow: hidden;
}

.intro-title-wrapper{
    height: 100%;
    animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
    25%{
        transform: translateY(-50px);
    }
    50%{
        transform: translateY(-100px);
    }
    75%{ 
        transform: translateY(-150px);
    }
    100%{
        transform: translateY(-200px); 
    }
    
}

.intro-title-item{
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: goldenrod;
    display: flex;
    align-items: center;
}

.intro-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.intro-bg{
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 23% 48%, 0% 0%);
    background-color: black;
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    right:0;
}


@media screen and (max-width: 480px) {
    .intro{
        flex-direction: column;
        margin-bottom: 18vh;
    }
    .intro-left-wrapper{
        padding: 10px;
        align-items: center;
        text-align: center;
        justify-content: center;
        height: 100%;
    }
    
    .intro-description{
        display: none;
    }
 
}