.about{
    height: 100vh;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.about-left{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

.about-right{
    flex:1;
}

.about-card.bg{
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: cornflowerblue;
}

.about-card{
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: absolute;
    overflow: hidden;
}

.about-image{
    width: 100%;
    height: 100%;
    object-fit:cover;
    border-radius: 20px;
}

.about-award-image{
    height: 140px;
    width: 140px;
    border-radius: 20px;
    object-fit: contain;
}

.about-title{
    font-weight: 400;
}

.about-subtitle{
    margin: 20px 0px;
}

.about-description{
    font-weight: 300;
}

.about-award{
    margin-top: 50px;    
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about-award-text{
    width: 70%;
}

.about-award-title{
    font-weight: bold;
    color:darkgray;
    margin-bottom: 10px;
    
}

@media screen and (max-width: 480px) {
    .about{
        flex-direction: column;
        text-align: center;
        /* margin-top:120px; */
    }

    .about-left{
        width: 100%;
    }
    
    .about-card{
        height: 30vh;
    }
    
    .about-card.bg{
        display: none;
    }

    .about-right{
        padding: 20px;
        margin-top: 13vh;
    }
    /* .about-award{
        display: none;
    } */
}